import React from "react"
import { Link } from "react-router-dom";
import { YMaps, Map, Clusterer, Placemark } from 'react-yandex-maps';

class FishingPlace extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chosenPopUpMap: null
        }
    }

    handleShowOnMapClick = e => {
        const { id } = e.target.dataset;



        this.setState({ chosenPopUpMap: id });

        setTimeout(() => {
            const mapPopUp = document.querySelector('.open');
            mapPopUp.scrollIntoView({ block: 'center', behavior: "smooth" });
        }, 50)
    }

    closeMapPopUp = () => {
        this.setState({ chosenPopUpMap: null });
    }

    render() {
        const { chosenPopUpMap } = this.state;
        const { arrayPlaces, offset, limit, filterState, innerWidth } = this.props;
        let showPlaces = arrayPlaces.slice(offset, limit);

        return (
            showPlaces && showPlaces.map((place, i) => {
                let fullDesc;
                if (place && place.full_description) {
                    const descFirstPart = place && place.full_description && place.full_description.split('<table>');
                    const descSecondPart = descFirstPart[1] && descFirstPart[1].split('</table>');
                    fullDesc = descFirstPart && descSecondPart && descFirstPart[0].concat(descSecondPart[1])
                } else {
                    fullDesc = '';
                }


                const coords = place && place.lat && place.lng && [place.lat, place.lng];

                const workTime = place.work_time.split('<br>');

                return place && <div className='fishing-place__wrapper' key={i}>
                    <div className={`fishing-place__cart ${filterState ? 'active' : ''}`}>
                        <div className='fishing-place__pic'>
                            <img src={place && place.images ? JSON.parse(place.images)[0] : './images/main/ihtiolog.png'} alt='fishing-pic' />
                        </div>
                        <div className='fishing-place__desc'>
                            <h3>{place && place.name}</h3>
                            <p dangerouslySetInnerHTML={{ __html: fullDesc ? fullDesc : place.full_description }} />
                            <h4>{place.region && place.region}</h4>
                            <div className='desc_footer'>
                                <p className='likes-count'>{place.likesCount}</p>
                                <div className='rating-wrapper'>
                                    <p className='rating'>Средняя оценка</p>
                                    <p className='rating-count'>{place.rating}</p>
                                </div>
                                {innerWidth >= 1320 && <button className={`show-on-map ${!filterState ? 'show' : ''}`} data-id={place.id} onClick={this.handleShowOnMapClick}>Показать на карте</button>}
                            </div>
                        </div>
                        <div className='fishing-place__types'>
                            <h3>Виды рыб</h3>
                            <p>{place.fish}</p>
                        </div>
                        <div className='fishing-place__details'>
                            <Link to={`/places/${place.id}`}>Подробнее</Link>
                            {workTime && <p className='work-time'>
                                Время ловли
                                <span>{workTime[0]}</span>
                                {workTime[1] && <span>{workTime[1]}</span>}
                            </p>}
                            <p className='price'>от {place.price_min} ₽</p>
                            <button className={`show-on-map ${filterState || innerWidth < 1320 ? 'show' : ''}`} data-id={place.id} onClick={this.handleShowOnMapClick}>Показать на карте</button>
                        </div>
                    </div>
                    <div className={`map-pop-up ${place.id === +chosenPopUpMap ? 'open' : ''}`}>
                        <YMaps>
                            <Map
                                defaultState={{ center: coords, zoom: 10 }}
                                width='100%'
                                height='100%'
                                onLoad={this.createTemplateLayoutFactory}
                                modules={['templateLayoutFactory', 'layout.ImageWithContent']}
                            >
                                <Clusterer
                                    options={{
                                        iconLayout: `<div class='clusterer__wrapper'></div>`,
                                        iconImageSize: [123, 123],
                                        gridSize: 250
                                    }}
                                >
                                    <Placemark
                                        key={place.id}
                                        geometry={coords}
                                        modules={[
                                            'geoObject.addon.balloon'
                                        ]}
                                        properties={{
                                            balloonContent: `
                                                        <div class='balloon__container'>
                                                            <h2>${place.name}</h2>
                                                            <div>
                                                                <p>${place.likesCount}</p>
                                                                <p>от ${place.price_min} ₽</p>
                                                            </div>
                                                            <a href=${`/places/${place.id}`}>Подробнеe</a>
                                                        </div>
                                                    `,
                                            iconContent: `
                                                        <div class='placemark__container'>
                                                            <img src='/images/main/placemark-pic.png' alt='placemark' />
                                                            <div class='placemark__content'>
                                                                <h2>${place.name}</h2>
                                                                <div>
                                                                    <p>${place.likesCount}</p>
                                                                    <p>от ${place.price_min} ₽</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    `,
                                        }}
                                        options={{
                                            iconLayout: 'default#imageWithContent',
                                            iconImageHref: '',
                                            iconImageSize: [324, 68],
                                            hideIconOnBalloonOpen: false
                                        }}
                                    />
                                </Clusterer>
                            </Map>
                        </YMaps>
                        <button className='map-pop-up__close-btn' onClick={this.closeMapPopUp}></button>
                    </div>
                </div>
            })
        )
    }
}

export default FishingPlace;