import React from "react"
import Slider from '@mui/material/Slider';

function valuetext(value) {
    return value;
}

class FilterPlaces extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: [],
            filterArray: null,
            rangeValue: []
        }
    }

    componentDidMount() {
        const { innerWidth, filterSubcategory } = this.props;

        const userTypeFilter = filterSubcategory && { ...filterSubcategory, subcategories: filterSubcategory.subcategories.filter(category => category.visability) };
        this.setState({ filterArray: userTypeFilter });
    }

    sort = () => {
        this.props.handleSortByFilter(this.state.value);
    }

    openParamsFilter = (e) => {
        const { value } = e.target.dataset;
        const { filterArray } = this.state;
        const openParams = { ...filterArray, subcategories: filterArray.subcategories.map(filter => filter.name === value ? { ...filter, selected: !filter.selected } : { ...filter }) }
        this.setState({ filterArray: openParams });
    }

    render() {
        const { filterArray } = this.state;
        const { handleSortByFilter } = this.props;

        return (
            <div>
                {filterArray && filterArray.subcategories.map((subcategory, i) => {
                    switch (subcategory.type) {
                        case 'checkbox':
                            return <div className={`filter-subcategory input ${filterArray.selected ? 'show' : ''}`} key={i}>
                                <input type='checkbox' id={`filter-${i}`} onClick={() => this.sort(subcategory.value)} onChange={(e) => {
                                    if (e.target.checked) {
                                        this.setState({ value: subcategory.value });
                                    }
                                }} />
                                <label htmlFor={`filter-${i}`}>{subcategory.name}</label>
                            </div>
                        case 'range':
                            let settingsDirection = {
                                value: '',
                                range: [],
                                marks: [
                                    {
                                        value: 0,
                                        label: '0',
                                    },
                                    {
                                        value: 5000,
                                        label: '5000',
                                    },
                                ]
                            }

                            switch (subcategory.direction) {
                                case 'price':
                                    settingsDirection = {
                                        value: 'Цена, ₽',
                                        range: [0, 5000],
                                        marks: [
                                            {
                                                value: 0,
                                                label: '0',
                                            },
                                            {
                                                value: 5000,
                                                label: '5000',
                                            }
                                        ]
                                    }
                                    break;
                                case 'distance':
                                    settingsDirection = {
                                        value: 'Расстояние, км',
                                        range: [0, 100],
                                        marks: [
                                            {
                                                value: 0,
                                                label: '0',
                                            },
                                            {
                                                value: 100,
                                                label: '100+',
                                            }
                                        ]
                                    }
                                    break;

                                case 'grade':
                                    settingsDirection = {
                                        value: 'Оценка',
                                        range: [0, 5],
                                        marks: [
                                            {
                                                value: 0,
                                                label: '0',
                                            },
                                            {
                                                value: 5,
                                                label: '5',
                                            }
                                        ]
                                    }
                                    break;

                                case 'ratio':
                                    settingsDirection = {
                                        value: 'Соотношение',
                                        range: [0, 5],
                                        marks: [
                                            {
                                                value: 0,
                                                label: '0',
                                            },
                                            {
                                                value: 5,
                                                label: '5',
                                            }
                                        ]
                                    }
                                    break;

                                default:
                                    break;
                            }

                            return <div className={`filter-subcategory range show ${subcategory.selected ? 'active' : ''}`} data-value={subcategory.name} onClick={this.openParamsFilter} key={i}>
                                {subcategory.name}
                                <div className={`range_inputs ${subcategory.selected ? 'show' : ''}`}>
                                    <p>{settingsDirection.value}</p>
                                    <Slider marks={settingsDirection.marks} getAriaValueText={valuetext} valueLabelDisplay="auto" min={settingsDirection.range[0]} max={settingsDirection.range[1]} defaultValue={settingsDirection.range} step={1} autoFocus
                                        onChange={(e, value) => handleSortByFilter(subcategory.direction, value)}
                                    />
                                </div>
                            </div>
                        case 'inside-checkbox':
                            let subcategories =
                                subcategory.direction !== 'fishTypes' ?
                                    subcategory.subcategories.sort()
                                    :
                                    subcategory.subcategories;

                            return <div className={`filter-subcategory show ${subcategory.selected ? 'active' : ''} ${subcategory.direction && subcategory.direction === 'highway' ? 'scroll' : ''}`} data-value={subcategory.name} onClick={this.openParamsFilter} key={i}>
                                {subcategory.name}
                                <div className='subcategory__wrapper'>
                                    {
                                        subcategories.map((item, i) => {
                                            const id = Math.random().toString(36).substring(2);
                                            return <p className={`subcategory__filter ${subcategory.selected ? 'show' : ''}`} key={i}>
                                                <input id={`input-${id}`} type='checkbox' onChange={() => (
                                                    handleSortByFilter(subcategory.direction && subcategory.direction, item === 'Сом, канальный сом' ? 'Сом' : item)
                                                )} />
                                                <label htmlFor={`input-${id}`}>{item}</label>
                                            </p>
                                        })
                                    }
                                </div>
                            </div>
                        default:
                            return <p className={`filter-subcategory show ${subcategory.selected ? 'active' : ''}`} key={i}>{subcategory.name}</p>
                    }
                })}
            </div>
        )
    }
}

export default FilterPlaces;

