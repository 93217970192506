import React from "react";

class AdsBlock extends React.Component {
    render() {
        return (
            <section className='ads-block'>
                <h2>
                    Yandex banner
                </h2>
            </section>
        )
    }
}

export default AdsBlock;