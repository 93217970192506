import React from 'react';
import { Link, Outlet } from 'react-router-dom';

import NavBar from './navbar';

class EditPlacesPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false
        }
    }

    render() {
        const { editing } = this.state;
        const { location, authUser, arrayPlaces } = this.props;

        return (
            <>
                <NavBar location={location} authUser={authUser} />
                <section className='main__content'>
                    <div>
                        <p>Управление аккаунтом</p>
                        <h2>Редактировать места</h2>
                        <p className='press-to-edit'>Нажмите <Link to={`/profile/edit-profile`}>здесь</Link>, чтобы редактировать свой аккаунт</p>
                    </div>
                    <h3>Ваши места</h3>
                    {!editing ?
                        <div className='places-table__wrapper'>
                            <div className='places-table'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th><p>Места</p></th>
                                            <th><p>Статус</p></th>
                                            <th><p>Дата</p></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {arrayPlaces && arrayPlaces.map((place, i) => {
                                            const date = place.updated_at.split(' ');
                                            const updateDate = date[0].split('-').join('.');
                                            return <tr key={i}>
                                                <td>{place.name}</td>
                                                <td><Link to={`/profile/edit-places/${place.id}`} onClick={() => this.setState({ editing: true })}>Редактировать</Link></td>
                                                <td>{updateDate}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        :
                        <Outlet />
                    }
                </section>
            </>
        )
    }
}

export default EditPlacesPage;