import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import qs from 'qs';

import Wrapper from './wrapper';

import RegistrationType from './components/authorization/registration-type';
import RegistrationPage from './components/authorization/registration-page';
import AuthorizationPage from './components/authorization/authorization-page';

import PrivateArea from './components/private-area/private-area';
import AccountManagement from './components/private-area/account-management';
import AccountSubscriptions from './components/private-area/account-subscriptions';
import EditProfilePage from './components/private-area/edit-profile';
import EditPlacesPage from './components/private-area/edit-places';
import EditPlacesForm from './components/private-area/edit-places-form';
import FavoritePage from './components/private-area/favorite-page';

let currentPosition;

function success(pos) {
  const crd = pos.coords;

  currentPosition = [crd.latitude, crd.longitude];

}

navigator.geolocation.getCurrentPosition(success);

const App = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLogged, setStateLogged] = useState(false);
  const [authUser, setAuthUser] = useState(null);
  const [stateDeletePopUp, setStateDeletePopUp] = useState(false);
  const [cartForDelete, setCartForDelete] = useState(null);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [bearerToken, setBearerToken] = useState(null);
  const [fishingPlaces, setFishingPlaces] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  let arrayPlaces = fishingPlaces && fishingPlaces.map(place => {
    const min = 1;
    const max = 5;
    const avgRating = (Math.random() * (max - min) + min).toFixed(1);
    const adminGrade = Math.floor(Math.random() * (max - min) + min);
    const adminRatio = Math.floor(Math.random() * (max - min) + min);

    return { ...place, selected: false, likesCount: 0, rating: avgRating, adminRatio: +adminRatio, adminGrade: +adminGrade, work_time: !place.work_time ? 'Неизвестно' : place.work_time, price_min: !place.price_min ? 1000 : place.price_min }
  })


  arrayPlaces && arrayPlaces.sort((a, b) => b.rating - a.rating);

  let resizeWindow = () => {
    setInnerWidth(window.innerWidth);
  }

  let arrayUsers = JSON.parse(localStorage.getItem('users'));
  if (arrayUsers === null) arrayUsers = [];

  useEffect(() => {
    window.addEventListener('resize', resizeWindow);

    const userBearer = localStorage.getItem('bearer');

    if (userBearer) {
      getUserData(userBearer);
      setStateLogged(true);
      setBearerToken(userBearer);

      const url = 'https://fishberries.veon-tech.ru/api/items/read';
      const options = {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${bearerToken}` },
        url
      }

      axios(options)
        .then(response => {
          setFishingPlaces(response.data.items);
        })
    } else {
      const url = 'https://fishberries.veon-tech.ru/api/items/read';
      const options = {
        method: 'GET',
        url
      }

      axios(options)
        .then(response => {
          setFishingPlaces(response.data.items);
        })
    }

    return () => window.removeEventListener("resize", resizeWindow);
  }, [bearerToken])

  const getUserData = (bearer) => {
    const url = 'https://fishberries.veon-tech.ru/api/users/read';
    const options = {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${bearer}` },
      url
    }

    axios(options)
      .then(response => setAuthUser(response.data))
  }

  const handleLogIn = (data) => {
    const url = 'https://fishberries.veon-tech.ru/api/users/login';
    const requestBody = {
      email: data.login,
      password: data.password
    }
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(requestBody),
      url
    }

    axios(options)
      .then(function (response) {
        navigate('/profile/cabinet');
        setStateLogged(true);
        getUserData(response.data.bearer);
        localStorage.setItem('bearer', response.data.bearer)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleLogOut = () => {
    const bearerToken = localStorage.getItem('bearer');
    const url = 'https://fishberries.veon-tech.ru/api/users/logout';
    const options = {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${bearerToken}` },
      url
    }

    axios(options)
      .then(response => {
        setStateLogged(false);
        setAuthUser(null);
        localStorage.removeItem('bearer');
        navigate('/');
      })
  }

  const registrationSubmit = data => {
    const url = 'https://fishberries.veon-tech.ru/api/users/register';
    const requestBody = {
      type: data.subscription,
      name: data.name,
      login: data.mail,
      password: data.password,
      email: data.mail,
      phone: data.number,
      image: data.userPhoto,
      city: data.city
    }
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(requestBody),
      url
    }

    axios(options)
      .then(function (response) {
        setAuthUser(response);
        setStateLogged(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const openDeletePopUp = e => {
    let value;
    if (e) {
      value = e.target.dataset.value;
    } else {
      value = null;
    }

    setStateDeletePopUp(!stateDeletePopUp);
    setCartForDelete(value);
  }

  return (
    <div className='container'>
      {arrayPlaces &&
        <Routes>
          <Route path='*' element={<Wrapper navigate={navigate} currentPosition={currentPosition} isLogged={isLogged} authUser={authUser} arrayPlaces={arrayPlaces} location={location.pathname} innerWidth={innerWidth} />} />
          <Route path='/registration' element={<RegistrationType />} />
          <Route path='/registration/form' element={<RegistrationPage location={location.pathname} registrationSubmit={registrationSubmit} />} />
          <Route path='/auth' element={<AuthorizationPage navigate={navigate} handleLogIn={handleLogIn} />} />
          {isLogged &&
            <Route path='/profile/' element={
              <PrivateArea
                innerWidth={innerWidth}
                location={location.pathname}
                authUser={authUser}
                stateDeletePopUp={stateDeletePopUp}
                handleLogOut={handleLogOut}
              />
            }>
              <Route path='cabinet' element={<AccountManagement location={location.pathname} authUser={authUser} />} />
              <Route path='subscriptions' element={<AccountSubscriptions location={location.pathname} authUser={authUser} />} />
              <Route path='edit-profile/' element={
                <EditProfilePage
                  location={location.pathname}
                  navigate={navigate}
                  authUser={authUser}
                  setAuthUser={setAuthUser}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  getUserData={getUserData}
                  bearerToken={bearerToken}
                />
              } />
              <Route path='edit-places/' element={<EditPlacesPage location={location.pathname} arrayPlaces={arrayPlaces} navigate={navigate} authUser={authUser} />}>
                <Route path=':id' element={<EditPlacesForm />} />
              </Route>
              <Route path='favorite' element={<FavoritePage innerWidth={innerWidth} authUser={authUser} cartForDelete={cartForDelete} stateDeletePopUp={stateDeletePopUp} openDeletePopUp={openDeletePopUp} />} />
            </Route>
          }
        </Routes>
      }
    </div>
  );
}

export default App;
