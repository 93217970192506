import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-comments-section/dist/index.css'
import 'font-awesome/css/font-awesome.min.css';
import 'animate.css';
import './styles/index.scss';

import items from './store/read.json';
import ScrollToTop from './scroll-to-top';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <ScrollToTop>
      <App fishingPlaces={items.items} />
    </ScrollToTop>
  </Router>
);