import React from "react";
import { Link } from 'react-router-dom';

class Footer extends React.Component {
    render() {
        return (
            <footer>
                <div className='footer__wrapper'>
                    <nav>
                        <ul>
                            <li><Link to='/terms-of-use'>Правила пользования</Link></li>
                            <li><Link to='/processing-agreement'>Соглашение на обработку данных</Link></li>
                            <li><a href='mailto:support@fishberries.ru'>support@fishberries.ru</a></li>
                        </ul>
                    </nav>
                    <p>2022 © Fishberries Inc.</p>
                </div>
            </footer>
        )
    }
}

export default Footer;