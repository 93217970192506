import React from 'react';
import { Link } from 'react-router-dom';

class NavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navBar: [
                {
                    visability: true,
                    id: 1,
                    value: 'cabinet',
                    name: 'Личный кабинет'
                },
                {
                    visability: true,
                    id: 2,
                    value: 'favorite',
                    name: 'Избранное'
                },
                {
                    visability: true,
                    id: 3,
                    value: 'subscriptions',
                    name: 'Подписки'
                },
                {
                    visability: this.props.authUser.user_type === 4 ? true : false,
                    id: 4,
                    value: 'edit-places',
                    name: 'Редактировать места'
                },
                {
                    visability: true,
                    id: 5,
                    value: 'edit-profile',
                    name: 'Редактировать профиль'
                },
                {
                    visability: this.props.authUser.user_type === 4 ? true : false,
                    id: 6,
                    value: 'create-owner-place',
                    name: 'Создать профиль владельца места'
                },
                {
                    visability: this.props.authUser.user_type === 4 ? true : false,
                    id: 7,
                    value: 'info-about-users',
                    name: 'Информация о пользователях'
                }
            ]
        }
    }

    render() {
        const { navBar } = this.state;
        const { location, authUser, handleHamburgerButtonClick } = this.props;
        const currentLocation = location && location.split('/').pop();

        return (
            <nav>
                <ul>
                    {navBar.map((nav, i) => (
                        nav.visability &&
                        <Link
                            to={`/profile/${nav.value}`}
                            onClick={handleHamburgerButtonClick}
                            key={i}
                        ><li
                            className={`${nav.value} ${nav.value === currentLocation ? 'active' : ''}`}
                        >{nav.name}</li></Link>
                    ))}
                </ul>
            </nav>
        )
    }
}

export default NavBar;