import React from "react";
import { Routes, Route } from 'react-router-dom';

import Header from './components/header';
import Main from './components/main';
import PlaceCart from './components/placecart/place-cart';
import Subscription from './components/subscription/subscription';
import Contact from './components/contact/contact';
import Footer from "./components/footer";
import ProcessingAgreement from "./components/footer/processing-agreement";
import TermsOfUse from "./components/footer/terms-of-use";

class Wrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sortByFilter: null,
            mainSortFilterPlaces: 'range',

            valueSortByPrice: [],
            valueSortByDistance: [],
            valueSortByGrade: [],
            valueSortByRatio: [],

            valueSortByFilterFishTypes: [],
            valuesSortByFilterHighway: [],
            valuesSortByFilterWorkTime: []
        }
    }

    handleChoiceMainFilter = value => {
        this.setState({ mainSortFilterPlaces: value });
    }

    setSortByFilter = array => {
        const places = document.querySelector('.main__fishing-places');
        this.setState({ sortByFilter: array });
        places.scrollIntoView({ block: 'start', behavior: "smooth" });
    }

    handleSortAscending = () => {
        const { sortByFilter, mainSortFilterPlaces } = this.state;
        const { arrayPlaces, currentPosition } = this.props;

        const distanceFromCurrent =
            !sortByFilter ?
                arrayPlaces.map(place => (
                    {
                        ...place, distance: Math.sqrt(
                            (currentPosition[0] - place.lat) * (currentPosition[0] - place.lat) + (currentPosition[1] - place.lng) * (currentPosition[1] - place.lng)
                        )
                    }
                ))
                :
                sortByFilter.map(place => (
                    {
                        ...place, distance: Math.sqrt(
                            (currentPosition[0] - place.lat) * (currentPosition[0] - place.lat) + (currentPosition[1] - place.lng) * (currentPosition[1] - place.lng)
                        )
                    }
                ))

        let sortByTag;

        switch (mainSortFilterPlaces) {
            case 'range':
                sortByTag = distanceFromCurrent.sort((a, b) => a.distance - b.distance);
                break;

            case 'price':
                sortByTag = distanceFromCurrent.sort((a, b) => a.price_min - b.price_min);
                break;

            case 'name':
                sortByTag = distanceFromCurrent.sort((a, b) => {
                    const nameA = a.name.toLowerCase();
                    const nameB = b.name.toLowerCase();
                    return nameA > nameB ? 1 : -1
                });
                break;

            case 'rating':
                sortByTag = distanceFromCurrent.sort((a, b) => a.rating - b.rating);
                break;

            default:
                break;
        }

        this.setState({ sortByFilter: sortByTag });
    }

    handleSortDescending = () => {
        const { sortByFilter, mainSortFilterPlaces } = this.state;
        const { arrayPlaces, currentPosition } = this.props;

        const distanceFromCurrent =
            !sortByFilter ?
                arrayPlaces.map(place => (
                    {
                        ...place, distance: Math.sqrt(
                            (currentPosition[0] - place.lat) * (currentPosition[0] - place.lat) + (currentPosition[1] - place.lng) * (currentPosition[1] - place.lng)
                        )
                    }
                ))
                :
                sortByFilter.map(place => (
                    {
                        ...place, distance: Math.sqrt(
                            (currentPosition[0] - place.lat) * (currentPosition[0] - place.lat) + (currentPosition[1] - place.lng) * (currentPosition[1] - place.lng)
                        )
                    }
                ))


        let sortByTag;

        switch (mainSortFilterPlaces) {
            case 'range':
                sortByTag = distanceFromCurrent.sort((a, b) => b.distance - a.distance);
                break;

            case 'price':
                sortByTag = distanceFromCurrent.sort((a, b) => b.price_min - a.price_min);
                break;

            case 'name':
                sortByTag = distanceFromCurrent.sort((a, b) => {
                    const nameA = a.name.toLowerCase();
                    const nameB = b.name.toLowerCase();
                    return nameA < nameB ? 1 : -1
                });
                break;

            case 'rating':
                sortByTag = distanceFromCurrent.sort((a, b) => b.rating - a.rating);
                break;

            default:
                break;
        }

        this.setState({ sortByFilter: sortByTag });
    }

    handleSortByFilter = (direction, value) => { // Фильтры
        const {
            valueSortByFilterFishTypes,
            valuesSortByFilterHighway,
            valuesSortByFilterWorkTime,

            valueSortByPrice,
            valueSortByDistance,
            valueSortByGrade,
            valueSortByRatio,
        } = this.state;
        const { arrayPlaces } = this.props;

        let values = []; // Проверка для фильтров выбора
        let regex = new RegExp(`${values.join('|')}`, 'gu'); // Проверка для шоссе

        switch (direction) { // Значения для фильтров выбора
            case 'fishTypes':
                valueSortByFilterFishTypes.length === 0 ?
                    values = [value]
                    :
                    valueSortByFilterFishTypes.every(item => item !== value) ?
                        values = valueSortByFilterFishTypes.concat([value])
                        :
                        values = valueSortByFilterFishTypes.filter(item => item !== value)
                break;

            case 'highway':
                valuesSortByFilterHighway.length === 0 ?
                    values = [value]
                    :
                    valuesSortByFilterHighway.every(item => item !== value) ?
                        values = valuesSortByFilterHighway.concat([value])
                        :
                        values = valuesSortByFilterHighway.filter(item => item !== value)
                regex = new RegExp(`${values.join('|')}`, 'gu');

                break;

            case 'workTime':
                valuesSortByFilterWorkTime.length === 0 ?
                    values = [value]
                    :
                    valuesSortByFilterWorkTime.every(item => item !== value) ?
                        values = valuesSortByFilterWorkTime.concat([value])
                        :
                        values = valuesSortByFilterWorkTime.filter(item => item !== value)
                break;

            default:
                break;
        }


        let filtered = arrayPlaces.filter(place => {
            switch (true) {
                case valueSortByFilterFishTypes.length > 0:
                case valuesSortByFilterHighway.length > 0:
                case valuesSortByFilterWorkTime.length > 0:
                case valueSortByDistance.length > 0:
                case valueSortByPrice.length > 0:
                case valueSortByGrade.length > 0:
                case valueSortByRatio.length > 0:
                    switch (direction) {
                        // Фильтры выбора

                        case 'fishTypes':
                            regex = new RegExp(`${valuesSortByFilterHighway.join('|')}`, 'gu');

                            return (
                                (values.every(fish => fish !== 'Прочие' ? place.fish && place.fish.includes(fish) : place.fish2)) && // По типу рыбы
                                (valuesSortByFilterHighway.length > 0 ? place.lake_highway && regex.test(place.lake_highway) : true) && // По названию шоссе
                                (
                                    valuesSortByFilterWorkTime.length === 0 || valuesSortByFilterWorkTime.length === 2 ?
                                        true :
                                        valuesSortByFilterWorkTime.every(time => time === 'Круглосуточно' ? place.work_time.includes(time) : !place.work_time.includes('Круглосуточно'))
                                ) && // По времени работы
                                (valueSortByPrice.length > 0 ? place.price_min >= valueSortByPrice[0] && place.price_min <= valueSortByPrice[1] : true) && // По цене
                                (
                                    valueSortByDistance.length > 0 ?
                                        (
                                            (place.lake_distance && (valueSortByDistance[0] > 0 || valueSortByDistance[1] < 100) && place.lake_distance >= valueSortByDistance[0] && place.lake_distance <= valueSortByDistance[1])
                                            ||
                                            (valueSortByDistance[0] === 0 && valueSortByDistance[1] === 100 && true)
                                        )
                                        : true
                                ) && // По расстонию от МКАД
                                (valueSortByGrade.length > 0 ? place.adminGrade >= valueSortByGrade[0] && place.adminGrade <= valueSortByGrade[1] : true) && // По оценке сайта
                                (valueSortByRatio.length > 0 ? place.adminRatio >= valueSortByRatio[0] && place.adminRatio <= valueSortByRatio[1] : true) // По соотношению цена/качество
                            )

                        case 'highway':
                            return (
                                (valueSortByFilterFishTypes.length > 0 ? valueSortByFilterFishTypes.every(fish => fish !== 'Прочие' ? place.fish && place.fish.includes(fish) : place.fish2) : true) && // По типу рыбы
                                (place.lake_highway && regex.test(place.lake_highway)) && // По названию шоссе
                                (
                                    valuesSortByFilterWorkTime.length === 0 || valuesSortByFilterWorkTime.length === 2 ?
                                        true :
                                        valuesSortByFilterWorkTime.every(time => time === 'Круглосуточно' ? place.work_time.includes(time) : !place.work_time.includes('Круглосуточно'))
                                ) && // По времени работы
                                (valueSortByPrice.length > 0 ? place.price_min >= valueSortByPrice[0] && place.price_min <= valueSortByPrice[1] : true) && // По цене
                                (
                                    valueSortByDistance.length > 0 ?
                                        (
                                            (place.lake_distance && (valueSortByDistance[0] > 0 || valueSortByDistance[1] < 100) && place.lake_distance >= valueSortByDistance[0] && place.lake_distance <= valueSortByDistance[1])
                                            ||
                                            (valueSortByDistance[0] === 0 && valueSortByDistance[1] === 100 && true)
                                        )
                                        : true
                                ) && // По расстонию от МКАД
                                (valueSortByGrade.length > 0 ? place.adminGrade >= valueSortByGrade[0] && place.adminGrade <= valueSortByGrade[1] : true) && // По оценке сайта
                                (valueSortByRatio.length > 0 ? place.adminRatio >= valueSortByRatio[0] && place.adminRatio <= valueSortByRatio[1] : true) // По соотношению цена/качество
                            )

                        case 'workTime':
                            regex = new RegExp(`${valuesSortByFilterHighway.join('|')}`, 'gu');

                            return (
                                (valueSortByFilterFishTypes.length > 0 ? valueSortByFilterFishTypes.every(fish => fish !== 'Прочие' ? place.fish && place.fish.includes(fish) : place.fish2) : true) && // По типу рыбы
                                (valuesSortByFilterHighway.length > 0 ? place.lake_highway && regex.test(place.lake_highway) : true) && // По названию шоссе
                                (
                                    values.length === 0 || values.length === 2 ?
                                        true :
                                        values.every(time => time === 'Круглосуточно' ? place.work_time.includes(time) : !place.work_time.includes('Круглосуточно'))
                                ) && // По времени работы
                                (valueSortByPrice.length > 0 ? place.price_min >= valueSortByPrice[0] && place.price_min <= valueSortByPrice[1] : true) && // По цене
                                (
                                    valueSortByDistance.length > 0 ?
                                        (
                                            (place.lake_distance && (valueSortByDistance[0] > 0 || valueSortByDistance[1] < 100) && place.lake_distance >= valueSortByDistance[0] && place.lake_distance <= valueSortByDistance[1])
                                            ||
                                            (valueSortByDistance[0] === 0 && valueSortByDistance[1] === 100 && true)
                                        )
                                        : true
                                ) && // По расстонию от МКАД
                                (valueSortByGrade.length > 0 ? place.adminGrade >= valueSortByGrade[0] && place.adminGrade <= valueSortByGrade[1] : true) && // По оценке сайта
                                (valueSortByGrade.length > 0 ? place.adminRatio >= valueSortByRatio[0] && place.adminRatio <= valueSortByRatio[1] : true) // По соотношению цена/качество
                            )

                        // Фильтры диапазона

                        case 'price':
                            regex = new RegExp(`${valuesSortByFilterHighway.join('|')}`, 'gu');

                            return (
                                (valueSortByFilterFishTypes.length > 0 ? valueSortByFilterFishTypes.every(fish => fish !== 'Прочие' ? place.fish && place.fish.includes(fish) : place.fish2) : true) && // По типу рыбы
                                (valuesSortByFilterHighway.length > 0 ? place.lake_highway && regex.test(place.lake_highway) : true) && // По названию шоссе
                                (
                                    valuesSortByFilterWorkTime.length === 0 || valuesSortByFilterWorkTime.length === 2 ?
                                        true :
                                        valuesSortByFilterWorkTime.every(time => time === 'Круглосуточно' ? place.work_time.includes(time) : !place.work_time.includes('Круглосуточно'))
                                ) && // По времени работы
                                (place.price_min >= value[0] && place.price_min <= value[1]) && // По цене
                                (
                                    valueSortByDistance.length > 0 ?
                                        (
                                            (place.lake_distance && (valueSortByDistance[0] > 0 || valueSortByDistance[1] < 100) && place.lake_distance >= valueSortByDistance[0] && place.lake_distance <= valueSortByDistance[1])
                                            ||
                                            (valueSortByDistance[0] === 0 && valueSortByDistance[1] === 100 && true)
                                        )
                                        : true
                                ) && // По расстонию от МКАД
                                (valueSortByGrade.length > 0 ? place.adminGrade >= valueSortByGrade[0] && place.adminGrade <= valueSortByGrade[1] : true) && // По оценке сайта
                                (valueSortByRatio.length > 0 ? place.adminRatio >= valueSortByRatio[0] && place.adminRatio <= valueSortByRatio[1] : true) // По соотношению цена/качество
                            )

                        case 'distance':
                            regex = new RegExp(`${valuesSortByFilterHighway.join('|')}`, 'gu');

                            return (
                                (valueSortByFilterFishTypes.length > 0 ? valueSortByFilterFishTypes.every(fish => fish !== 'Прочие' ? place.fish && place.fish.includes(fish) : place.fish2) : true) && // По типу рыбы
                                (valuesSortByFilterHighway.length > 0 ? place.lake_highway && regex.test(place.lake_highway) : true) && // По названию шоссе
                                (
                                    valuesSortByFilterWorkTime.length === 0 || valuesSortByFilterWorkTime.length === 2 ?
                                        true :
                                        valuesSortByFilterWorkTime.every(time => time === 'Круглосуточно' ? place.work_time.includes(time) : !place.work_time.includes('Круглосуточно'))
                                ) && // По времени работы
                                (valueSortByPrice.length > 0 ? place.price_min >= valueSortByPrice[0] && place.price_min <= valueSortByPrice[1] : true) && // По цене
                                (
                                    (
                                        (place.lake_distance && (value[0] > 0 || value[1] < 100) && place.lake_distance >= value[0] && place.lake_distance <= value[1])
                                        ||
                                        (value[0] === 0 && value[1] === 100 && true)
                                    )
                                ) && // По расстонию от МКАД
                                (valueSortByGrade.length > 0 ? place.adminGrade >= valueSortByGrade[0] && place.adminGrade <= valueSortByGrade[1] : true) && // По оценке сайта
                                (valueSortByRatio.length > 0 ? place.adminRatio >= valueSortByRatio[0] && place.adminRatio <= valueSortByRatio[1] : true) // По соотношению цена/качество
                            )

                        case 'grade':
                            regex = new RegExp(`${valuesSortByFilterHighway.join('|')}`, 'gu');

                            return (
                                (valueSortByFilterFishTypes.length > 0 ? valueSortByFilterFishTypes.every(fish => fish !== 'Прочие' ? place.fish && place.fish.includes(fish) : place.fish2) : true) && // По типу рыбы
                                (valuesSortByFilterHighway.length > 0 ? place.lake_highway && regex.test(place.lake_highway) : true) && // По названию шоссе
                                (
                                    valuesSortByFilterWorkTime.length === 0 || valuesSortByFilterWorkTime.length === 2 ?
                                        true :
                                        valuesSortByFilterWorkTime.every(time => time === 'Круглосуточно' ? place.work_time.includes(time) : !place.work_time.includes('Круглосуточно'))
                                ) && // По времени работы
                                (valueSortByPrice.length > 0 ? place.price_min >= valueSortByPrice[0] && place.price_min <= valueSortByPrice[1] : true) && // По цене
                                (
                                    valueSortByDistance.length > 0 ?
                                        (
                                            (place.lake_distance && (valueSortByDistance[0] > 0 || valueSortByDistance[1] < 100) && place.lake_distance >= valueSortByDistance[0] && place.lake_distance <= valueSortByDistance[1])
                                            ||
                                            (valueSortByDistance[0] === 0 && valueSortByDistance[1] === 100 && true)
                                        )
                                        : true
                                ) && // По расстонию от МКАД
                                (place.adminGrade >= value[0] && place.adminGrade <= value[1]) && // По оценке сайта
                                (valueSortByRatio.length > 0 ? place.adminRatio >= valueSortByRatio[0] && place.adminRatio <= valueSortByRatio[1] : true) // По соотношению цена/качество
                            )

                        case 'ratio':
                            regex = new RegExp(`${valuesSortByFilterHighway.join('|')}`, 'gu');

                            return (
                                (valueSortByFilterFishTypes.length > 0 ? valueSortByFilterFishTypes.every(fish => fish !== 'Прочие' ? place.fish && place.fish.includes(fish) : place.fish2) : true) && // По типу рыбы
                                (valuesSortByFilterHighway.length > 0 ? place.lake_highway && regex.test(place.lake_highway) : true) && // По названию шоссе
                                (
                                    valuesSortByFilterWorkTime.length === 0 || valuesSortByFilterWorkTime.length === 2 ?
                                        true :
                                        valuesSortByFilterWorkTime.every(time => time === 'Круглосуточно' ? place.work_time.includes(time) : !place.work_time.includes('Круглосуточно'))
                                ) && // По времени работы
                                (valueSortByPrice.length > 0 ? place.price_min >= valueSortByPrice[0] && place.price_min <= valueSortByPrice[1] : true) && // По цене
                                (
                                    valueSortByDistance.length > 0 ?
                                        (
                                            (place.lake_distance && (valueSortByDistance[0] > 0 || valueSortByDistance[1] < 100) && place.lake_distance >= valueSortByDistance[0] && place.lake_distance <= valueSortByDistance[1])
                                            ||
                                            (valueSortByDistance[0] === 0 && valueSortByDistance[1] === 100 && true)
                                        )
                                        : true
                                ) && // По расстонию от МКАД
                                (valueSortByGrade.length > 0 ? place.adminGrade >= valueSortByGrade[0] && place.adminGrade <= valueSortByGrade[1] : true) && // По оценке сайта
                                (place.adminRatio >= value[0] && place.adminRatio <= value[1]) // По соотношению цена/качество
                            )

                        default:
                            return place;
                    }

                default:
                    switch (direction) {
                        case 'fishTypes':
                            return values.every(fish => fish !== 'Прочие' ? place.fish && place.fish.includes(fish) : place.fish2)

                        case 'highway':
                            return values.length > 0 ? place.lake_highway && regex.test(place.lake_highway) : place

                        case 'workTime':
                            return values.length === 0 || values.length === 2 ?
                                place :
                                values.every(time => time === 'Круглосуточно' ? place.work_time.includes(time) : !place.work_time.includes('Круглосуточно'));

                        case 'price':
                            return place.price_min >= value[0] && place.price_min <= value[1]

                        case 'distance':
                            return (
                                (place.lake_distance && (value[0] > 0 || value[1] < 100) && place.lake_distance >= value[0] && place.lake_distance <= value[1])
                                ||
                                (value[0] === 0 && value[1] === 100 && place)
                            )

                        case 'grade':
                            return place.adminGrade >= value[0] && place.adminGrade <= value[1]

                        case 'ratio':
                            return place.adminRatio >= value[0] && place.adminRatio <= value[1]

                        default:
                            return place;
                    }
            }
        })

        this.setState({
            sortByFilter: filtered,
            valueSortByFilterFishTypes: direction === 'fishTypes' ? values : valueSortByFilterFishTypes,
            valuesSortByFilterHighway: direction === 'highway' ? values : valuesSortByFilterHighway,
            valuesSortByFilterWorkTime: direction === 'workTime' ? values : valuesSortByFilterWorkTime,
            valueSortByPrice: direction === 'price' ? (value[0] !== 0 || value[1] !== 5000 ? value : []) : valueSortByPrice,
            valueSortByDistance: direction === 'distance' ? (value[0] !== 0 || value[1] !== 100 ? value : []) : valueSortByDistance,
            valueSortByGrade: direction === 'grade' ? (value[0] !== 0 || value[1] !== 5 ? value : []) : valueSortByGrade,
            valueSortByRatio: direction === 'ratio' ? (value[0] !== 0 || value[1] !== 5 ? value : []) : valueSortByRatio
        })
    }

    render() {
        const { sortByFilter, mainSortFilterPlaces } = this.state;
        const { stateYMaps, navigate, currentPosition, isLogged, authUser, arrayPlaces, location, innerWidth } = this.props;

        return (
            <>
                <Header isLogged={isLogged} authUser={authUser} />
                <Routes>
                    <Route path='/' element={<Main
                        stateYMaps={stateYMaps}
                        navigate={navigate}
                        authUser={authUser}
                        urrentPosition={currentPosition}
                        arrayPlaces={arrayPlaces}
                        innerWidth={innerWidth}
                        sortByFilter={sortByFilter}
                        mainSortFilterPlaces={mainSortFilterPlaces}
                        handleChoiceMainFilter={this.handleChoiceMainFilter}
                        setSortByFilter={this.setSortByFilter}
                        handleSortAscending={this.handleSortAscending}
                        handleSortDescending={this.handleSortDescending}
                        handleSortByFilter={this.handleSortByFilter}
                    />} />
                    <Route path='/places/:place' element={<PlaceCart authUser={authUser} arrayPlaces={arrayPlaces} location={location} innerWidth={innerWidth} />} />
                    <Route path='/subscription' element={<Subscription />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/processing-agreement' element={<ProcessingAgreement />} />
                    <Route path='/terms-of-use' element={<TermsOfUse />} />
                </Routes>
                <Footer />
            </>
        )
    }
}

export default Wrapper;
