import React from "react";
import { Link } from 'react-router-dom';

class RegistrationType extends React.Component {
    render() {
        return (
            <>
                <Link to='/' className='close_auth_btn'><img src='/images/icons/close-icon.svg' alt='close' /></Link>
                <section className='registration-type'>
                    <h2>Создайте Ваш новый аккаунт!</h2>
                    <p>Уже зарегистрированы? <Link to='/auth'>Войти</Link></p>
                    <Link to='/registration/form'>Зарегистрироваться</Link>
                    <p className='caption'><strong>Вы владелец рыбного места?</strong> Отправьте заявку на <a href='mailto:support@fishberries.ru'>support@fishberries.ru</a></p>
                </section>
            </>
        )
    }
}

export default RegistrationType;