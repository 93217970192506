import React from 'react';
import { Link } from 'react-router-dom';
import { YMaps, Map, Clusterer, Placemark } from 'react-yandex-maps';

import AdsBlock from './ads-block';
import FishingPlace from './fishing-block';
import FishingSlider from './fishing-slider';
import FilterPlaces from './filter-places';

const mainFilters = [
    {
        name: 'По цене',
        value: 'price'
    },
    {
        name: 'По расстоянию',
        value: 'range'
    },
    {
        name: 'По рейтингу',
        value: 'rating'
    },
    {
        name: 'По названию',
        value: 'name'
    }
]

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 0,
            limit: 5,
            filterState: false,
            filterArray: [
                {
                    name: 'Рыбалка',
                    selected: false,
                    subcategories: [
                        {
                            visability: true,
                            selected: false,
                            type: 'range',
                            direction: 'price',
                            name: 'Цена'
                        },
                        {
                            visability: true,
                            selected: false,
                            type: 'inside-checkbox',
                            direction: 'limitation',
                            name: 'Ограничение по вылову',
                            subcategories: [
                                'Безлимит',
                                'Безлимит с ограничением',
                                'Оплата по факту',
                                'Включена в путёвку'
                            ]
                        },
                        {
                            visability: true,
                            selected: false,
                            type: 'inside-checkbox',
                            direction: 'fishTypes',
                            name: 'Виды рыбы',
                            subcategories: [
                                'Осетр',
                                'Форель',
                                'Карп',
                                'Щука',
                                'Сом, канальный сом',
                                'Карась',
                                'Прочие'
                            ]
                        },
                        {
                            visability: this.props.authUser && this.props.authUser.user_type > 0 ? true : false,
                            selected: false,
                            type: 'inside-checkbox',
                            direction: 'last_stocking',
                            name: 'Последние зарыбление',
                            subcategories: [
                                'Осетр',
                                'Форель',
                                'Карп',
                                'Щука',
                                'Сом, канальный сом',
                                'Карась',
                                'Прочие'
                            ]
                        },
                        {
                            visability: this.props.authUser && this.props.authUser.user_type > 1 ? true : false,
                            selected: false,
                            type: 'inside-checkbox',
                            direction: 'planned_stocking',
                            name: 'Плановые зарыбления',
                            subcategories: [
                                'Осетр',
                                'Форель',
                                'Карп',
                                'Щука',
                                'Сом, канальный сом',
                                'Карась',
                                'Прочие'
                            ]
                        },
                        {
                            visability: this.props.authUser && this.props.authUser.user_type > 1 ? true : false,
                            selected: false,
                            type: 'inside-checkbox',
                            direction: 'winter_fishing',
                            name: 'Рыбалка зимой',
                            subcategories: [
                                'Рыбалка на льду',
                                'По открытой воде'
                            ]
                        },
                        {
                            visability: this.props.authUser && this.props.authUser.user_type > 1 ? true : false,
                            type: 'checkbox',
                            name: 'Трофейная рыба'
                        },
                        {
                            visability: this.props.authUser && this.props.authUser.user_type > 1 ? true : false,
                            type: 'checkbox',
                            name: 'Возможность отпускать рыбу (принцип ПО)',
                        },
                        {
                            visability: this.props.authUser && this.props.authUser.user_type > 1 ? true : false,
                            type: 'checkbox',
                            name: 'Плановые акции, соревнования'
                        },
                        {
                            visability: true,
                            type: 'checkbox',
                            name: 'Возможность онлайн заказа'
                        },
                    ]
                },
                {
                    name: 'Пруд',
                    selected: false,
                    subcategories: [
                        {
                            visability: true,
                            selected: false,
                            direction: 'distance',
                            type: 'range',
                            name: 'Расстояние от МКАД'
                        },
                        {
                            visability: true,
                            selected: false,
                            type: 'inside-checkbox',
                            direction: 'highway',
                            name: 'Район',
                            subcategories: [
                                'Подольский',
                                'Чеховский'
                            ]
                        },
                        {
                            visability: true,
                            selected: false,
                            type: 'inside-checkbox',
                            direction: 'highway',
                            name: 'Шоссе',
                            subcategories: [
                                'Калужское',
                                'Рижское',
                                'Симферопольское',
                                'Пятницкое',
                                'Варшавское',
                                'Дмитровское',
                                'Новорязанское',
                                'Каширское',
                                'Ярославское',
                                'Минское',
                                'Киевское',
                                'Егорьевское',
                                'Ленинградское',
                                'Щелковское',
                                'Рублево-Успенское',
                                'Горьковское',
                                'Новорижское',
                                'Можайское',
                                'Волоколамское',
                                'Боровское',
                                'Носовихинское',
                                'Рязанское'
                            ]
                        },
                        {
                            visability: true,
                            selected: false,
                            type: 'inside-checkbox',
                            direction: 'workTime',
                            name: 'Время работы пруда',
                            subcategories: [
                                'Круглосуточно',
                                'Ограниченное время'
                            ]
                        },
                        {
                            visability: true,
                            selected: false,
                            type: 'inside-checkbox',
                            name: 'Максимальное количество мест ловли на пруду',
                            subcategories: [
                                'до 50',
                                'до 150',
                                'свыше 150'
                            ]
                        },
                        {
                            visability: true,
                            selected: false,
                            type: 'inside-checkbox',
                            name: 'Качество дороги до парковки',
                            subcategories: [
                                'Асфальтированная',
                                'Часть грунтовой до 1 км',
                                'Часть грунтовой свыше 1 км'
                            ]
                        },
                        {
                            visability: this.props.authUser && this.props.authUser.user_type > 0 ? true : false,
                            selected: false,
                            type: 'inside-checkbox',
                            name: 'Расстояние от парковки до места ловли',
                            subcategories: [
                                'до 50 м',
                                'до 200 м',
                                'свыше 200 м'
                            ]
                        },
                        {
                            visability: this.props.authUser && this.props.authUser.user_type > 1 ? true : false,
                            selected: false,
                            type: 'inside-checkbox',
                            name: 'Качество 4G',
                            subcategories: [
                                'Хорошее',
                                'Удовлетворительное',
                                'Предоставляется Wi-Fi'
                            ]
                        }
                    ]
                },
                {
                    name: 'Отдых',
                    selected: false,
                    subcategories: [
                        {
                            visability: true,
                            selected: false,
                            name: 'Цена',
                            type: 'inside-checkbox',
                            subcategories: [
                                'до 10',
                                'свыше 10'
                            ]
                        },
                        {
                            visability: true,
                            type: 'checkbox',
                            name: 'Ограничение по вылову'
                        },
                        {
                            visability: true,
                            type: 'checkbox',
                            name: 'Виды рыбы'
                        },
                        {
                            visability: true,
                            type: 'checkbox',
                            name: 'Последние зарыбление'
                        }
                    ]
                },
                {
                    name: 'Услуги',
                    selected: false,
                    subcategories: [
                        {
                            visability: true,
                            type: 'checkbox',
                            name: 'Аренда снастей',
                            value: 'Прокат'
                        },
                        {
                            visability: true,
                            type: 'checkbox',
                            name: 'Приобретение снастей, наживки, прикормки, блесен и прочего',
                            value: 'купить, покупка, снасти, наживки, инвентарь'
                        },
                        {
                            visability: true,
                            type: 'checkbox',
                            name: 'Наличие инструктора',
                            value: 'инструктор, сопровождающий'
                        },
                        {
                            visability: this.props.authUser && this.props.authUser.user_type > 0 ? true : false,
                            type: 'checkbox',
                            name: 'Копчение и обработка рыбы',
                            value: 'копчение, обработка'
                        },
                        {
                            visability: this.props.authUser && this.props.authUser.user_type > 0 ? true : false,
                            type: 'checkbox',
                            name: 'Продажа живца',
                            value: 'живца'
                        },
                        {
                            visability: this.props.authUser && this.props.authUser.user_type > 1 ? true : false,
                            type: 'checkbox',
                            name: 'Продажа живой рыбы',
                            value: 'купить, рыба, живая'
                        },
                        {
                            visability: true,
                            type: 'checkbox',
                            name: 'Наличие мест для купания',
                            value: 'купания, купаться, плавать, плавание'
                        }
                    ]
                },
                {
                    name: 'Аналитика',
                    selected: false,
                    subcategories: [
                        {
                            visability: true,
                            selected: false,
                            direction: 'grade',
                            type: 'range',
                            name: 'Оценка по шкале сайта'
                        },
                        {
                            visability: true,
                            selected: false,
                            direction: 'ratio',
                            type: 'range',
                            name: 'Соотношение цена/качество'
                        }
                    ]
                }
            ],
            searchValue: '',
            mainFiltersClick: false
        };

        this.wrapperRef = React.createRef();
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleFilterClick = () => {
        const { filterArray, filterState } = this.state;
        const { innerWidth } = this.props;

        const chosenCategory = filterArray.map(category => (
            { ...category, selected: false }
        ))

        filterState ?
            document.body.style.overflow = innerWidth < 1024 && 'scroll'
            :
            document.body.style.overflow = innerWidth < 1024 ? 'hidden' : 'scroll';

        this.setState({ filterState: !this.state.filterState, filterArray: chosenCategory });
    }

    handleSubcategoriesClick = e => {
        const { value } = e.target.dataset;
        const { filterArray } = this.state;
        const chosenCategory = filterArray.map(category => (
            category.name === value ? { ...category, selected: !category.selected } : { ...category }
        ))

        this.setState({ filterArray: chosenCategory });
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        const { innerWidth } = this.props;

        if (innerWidth < 1024 && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            document.body.style.overflow = 'scroll';
            this.setState({ filterState: false });
        }
    }

    handleShowMoreClick = () => {
        this.setState({
            offset: this.state.offset,
            limit: this.state.limit + 5
        })
    }

    handleSearchValue = e => {
        this.setState({ searchValue: e.target.value })
    }

    handleSearchClick = () => {
        const { searchValue, mainFiltersClick } = this.state;
        const { arrayPlaces, setSortByFilter } = this.props;

        const filtered = arrayPlaces.filter(place => {
            let value = searchValue.split(', ');
            return value.every(v => (place.region && place.region.toLowerCase().includes(v.toLowerCase()))
                ||
                (place.address && place.address.toLowerCase().includes(v.toLowerCase()))
                ||
                (place.name && place.name.toLowerCase().includes(v.toLowerCase()))
            )
        });

        this.setState({ searchValue: '' });
        setSortByFilter(filtered);
    }

    render() {
        const { offset, limit, filterState, filterArray, searchValue, mainFiltersClick } = this.state;
        const {
            arrayPlaces,
            stateYMaps,
            navigate,
            innerWidth,
            sortByFilter,
            handleSortAscending,
            handleSortDescending,
            handleSortByFilter,
            mainSortFilterPlaces,
            handleChoiceMainFilter
        } = this.props;

        return (
            <>
                <main className={filterState && innerWidth < 1024 ? 'disable' : ''}>
                    <div className='main__title-wrapper'>
                        <section className='main__title'>
                            <h1>
                                Платная рыбалка
                            </h1>
                            <p>
                                Поиск платных прудов и рыболовных баз. Каталог, отзывы, рейтинг.
                            </p>
                            <div className={searchValue ? 'active-search' : ''}>
                                <input type='text' placeholder='Город, область, место' value={searchValue} onChange={this.handleSearchValue} />
                                {innerWidth >= 1024 ? <button onClick={this.handleSearchClick}>Найти</button> : <button onClick={this.handleSearchClick}></button>}
                                <div className='search-results'>
                                    {searchValue &&
                                        arrayPlaces.map((place, i) => {
                                            let value = searchValue.split(', ');
                                            return value.every(v => (place.region && place.region.toLowerCase().includes(v.toLowerCase()))
                                                ||
                                                (place.address && place.address.toLowerCase().includes(v.toLowerCase()))
                                                ||
                                                (place.name && place.name.toLowerCase().includes(v.toLowerCase())))

                                                && <Link to={`places/${place.id}`} key={i}>{place.name}, {place.address}, {place.region}</Link>
                                        })
                                    }
                                </div>
                            </div>
                        </section>
                    </div>
                    <AdsBlock />
                    <section className='main__fishing-places'>
                        <div className='fishing-places__title'>
                            <button className={`filter-button ${filterState ? 'open' : ''}`} onClick={this.handleFilterClick}>Фильтр</button>
                            <div>
                                <h2>Выбрать место для рыбалки</h2>
                                <div>
                                    <button className='filter-arrow up' onClick={handleSortAscending}></button>
                                    <button className='filter-arrow down' onClick={handleSortDescending}></button>
                                    <div className={`main_filters ${mainFiltersClick ? 'active' : ''}`}>
                                        {mainFilters.map((filter, i) => (
                                            filter.value === mainSortFilterPlaces &&
                                            <p className='active' onClick={() => this.setState({ mainFiltersClick: !mainFiltersClick })} key={i}>{filter.name}</p>
                                        ))}
                                        <div className={`disabled ${mainFiltersClick ? 'active' : ''}`}>
                                            {mainFilters.map((filter, i) => (
                                                filter.value !== mainSortFilterPlaces &&
                                                <p
                                                    onClick={() => {
                                                        handleChoiceMainFilter(filter.value);
                                                        this.setState({ mainFiltersClick: false });
                                                    }}
                                                    key={i}
                                                >
                                                    {filter.name}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='fishing-places__list'>
                            <section className={`fishing-places__filter ${filterState ? 'show' : ''}`} ref={innerWidth < 1024 ? this.setWrapperRef : null}>
                                {innerWidth < 1024 && <button className='filter_title' onClick={this.handleFilterClick}>Фильтр</button>}
                                {filterState &&
                                    filterArray.map((filterName, i) => (
                                        <div key={i}>
                                            <p className='filter-category' data-value={filterName.name} onClick={this.handleSubcategoriesClick}>
                                                {filterName.name}
                                            </p>
                                            {filterName.selected &&
                                                <FilterPlaces
                                                    sortByFilter={sortByFilter}
                                                    handleSortByFilter={handleSortByFilter}
                                                    innerWidth={innerWidth}
                                                    filterSubcategory={filterName}
                                                />
                                            }
                                        </div>
                                    ))
                                }
                                {/* <p className='list_quality'>Найдено {sortByFilter ? sortByFilter.length : arrayPlaces.length}</p> */}
                            </section>
                            {innerWidth >= 1024 ?
                                <div className='fishing-places__wrapper'>
                                    {sortByFilter ?
                                        <FishingPlace filterState={filterState} offset={offset} limit={limit} arrayPlaces={sortByFilter} innerWidth={innerWidth} />
                                        :
                                        <FishingPlace filterState={filterState} offset={offset} limit={limit} arrayPlaces={arrayPlaces} innerWidth={innerWidth} />
                                    }
                                </div>
                                :
                                <div>
                                    {sortByFilter ?
                                        <FishingSlider arrayPlaces={sortByFilter} />
                                        :
                                        <FishingSlider arrayPlaces={arrayPlaces} />
                                    }
                                </div>
                            }
                        </div>
                        {((sortByFilter && limit < sortByFilter.length) || (!sortByFilter && limit < arrayPlaces.length)) && innerWidth >= 1024 && <button className='show-more' onClick={this.handleShowMoreClick}>Показать ещё...</button>}
                    </section>
                    <AdsBlock />
                    <section className='main__map' style={{ width: '100%' }}>
                        <h2>Места ловли</h2>
                        <div className='map__wrapper'>
                            <YMaps>
                                <Map
                                    defaultState={{ center: [55.813461, 37.531548], zoom: 7 }}
                                    style={{ width: '100%', height: '100%' }}
                                    options={{
                                        autoFitToViewport: 'always'
                                    }}
                                    onLoad={this.createTemplateLayoutFactory}
                                    modules={['templateLayoutFactory', 'layout.ImageWithContent']}
                                >
                                    <Clusterer
                                        options={{
                                            iconLayout: `<div class='clusterer__wrapper'></div>`,
                                            iconImageSize: [123, 123],
                                            gridSize: 250,
                                            groupByCoordinates: false
                                        }}
                                    >
                                        {!sortByFilter ?
                                            arrayPlaces.map(place => {
                                                const coords = place.lat && place.lng && [place.lat, place.lng];
                                                return <Placemark
                                                    key={place.id}
                                                    geometry={coords}
                                                    modules={[
                                                        'geoObject.addon.balloon'
                                                    ]}
                                                    properties={{
                                                        iconContent: `
                                                        <div class='placemark__container'>
                                                            <img src='./images/main/placemark-pic.png' alt='placemark' />
                                                            <div class='placemark__content'>
                                                                <h2>${place.name}</h2>
                                                                <div>
                                                                    <p>${place.likesCount}</p>
                                                                    <p>от ${place.price_min} ₽</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    `,
                                                    }}
                                                    onClick={() => navigate(`/places/${place.id}`)}
                                                    options={{
                                                        iconLayout: 'default#imageWithContent',
                                                        iconImageHref: '',
                                                        iconImageSize: [324, 68],
                                                        hideIconOnBalloonOpen: false
                                                    }}
                                                />
                                            })
                                            :
                                            sortByFilter.map(place => {
                                                const coords = place && place.lat && place.lng && [place.lat, place.lng];
                                                return place && <Placemark
                                                    key={place.id}
                                                    geometry={coords}
                                                    modules={[
                                                        'geoObject.addon.balloon'
                                                    ]}
                                                    properties={{
                                                        iconContent: `
                                                        <div class='placemark__container'>
                                                            <img src='./images/main/placemark-pic.png' alt='placemark' />
                                                            <div class='placemark__content'>
                                                                <h2>${place.name}</h2>
                                                                <div>
                                                                    <p>${place.likesCount}</p>
                                                                    <p>от ${place.price_min} ₽</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    `,
                                                    }}
                                                    onClick={() => navigate(`/places/${place.id}`)}
                                                    options={{
                                                        iconLayout: 'default#imageWithContent',
                                                        iconImageHref: '',
                                                        iconImageSize: [324, 68],
                                                        hideIconOnBalloonOpen: false
                                                    }}
                                                />
                                            })
                                        }

                                    </Clusterer>
                                </Map>
                            </YMaps>
                        </div>
                    </section>
                </main>
            </>
        )
    }
}

export default Main;